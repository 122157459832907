import styled                    from '@emotion/styled';
import { readableColor, darken } from 'polished';

export const Nav = styled.nav`
  width: 100%;
  background-color: ${props => props.theme.config.components.subNavigation.backgroundColor ? props.theme.config.components.subNavigation.backgroundColor : props.theme.brand_color};
  position: sticky;
  top: ${props => props.theme.config.components.header.collapsedHeight};
  z-index: 3;
  overflow: hidden;
  @media (max-width: ${props => props.breakpoint}px) {
    position: relative;
    top: 0;
    .int-mobile-menu {
      display: block !important;
    }
  }
  .int-mobile-menu {
    display: ${props => props.mounted ? 'none' : 'block !important'};
    text-align: center;
    button {
      outline: none;
      border: none;
      display: inline-block;
      font-weight: bold;
      text-decoration: none;
      padding: 20px;
      background-color: unset;
      color: ${props => readableColor(props.theme.brand_color)};
    }
    svg {
      vertical-align: text-top;
    }
  }
  a, svg {
    color: ${props => readableColor(props.theme.config.components.subNavigation.backgroundColor ? props.theme.config.components.subNavigation.backgroundColor : props.theme.brand_color)};
  }
  a {
    text-decoration: none;
    background-image: none;
    padding: 5px 15px;
    border-radius: 30px;
    transition: background-color 0.1s;
    line-height: 30px;
    &.current,
    &:hover {
      color: ${props => readableColor(darken(0.1, props.theme.config.components.subNavigation.backgroundColor ? props.theme.config.components.subNavigation.backgroundColor : props.theme.brand_color))};
      background-color: ${props => darken(0.1, props.theme.config.components.subNavigation.backgroundColor ? props.theme.config.components.subNavigation.backgroundColor : props.theme.brand_color)};
    }
  }
  ul {
    width: 90%;
    max-width: ${props => props.theme.config.components.wrapper.width}px;
    margin: 0 auto;
    min-height: 40px;
    display: flex;
    align-items: center;
    align-content: stretch;
    flex-wrap: wrap;
    @media (max-width: ${props => props.breakpoint}px) {
      flex-direction: column;
    }
    li {
      list-style-type: none;
      margin: 2px 10px 2px 0;
    }
  }
`;
