import React                   from 'react';
import { graphql }             from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import HTMLEllipsis            from 'react-lines-ellipsis/lib/html'
import { FormattedMessage }    from 'react-intl';

import { findT }    from '@interness/web-core/src/components/_helpers';
import SEO          from '@interness/web-core/src/components/modules/SEO/SEO';
import RandomBrandHeaderImages
                    from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import BrandsSubnavigation
                    from '@interness/brands-addon/src/components/modules/BrandsSubnavigation/BrandsSubnavigation';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Separator    from '@interness/web-core/src/components/structure/Separator/Separator';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Logo         from '@interness/web-core/src/components/media/Logo/Logo';
import Button       from '@interness/web-core/src/components/elements/Button/Button';
import Link         from '@interness/web-core/src/components/elements/Link/Link';
import routes       from '@interness/web-core/config/routes';

import useTheme from '../../../../useTheme';
import * as S   from './styles';

const BrandPromoterList = props => {
  const { brand, lang } = props;
  const translations = findT(brand.translations, lang);
  return (
    <>
      <Container>
        <Row>
          <Col md={8}>
            <div>
              <S.StyledImg fluid={brand.header_images[0].file.localFile.childImageSharp.fluid}
                           alt={brand.display_name}/>
            </div>
            <S.ShortDescription>
              {translations.short_description
                ? <p>{translations.short_description}</p>
                : <HTMLEllipsis unsafeHTML={translations.description} maxLine={3} ellipsis={'...'}/>
              }
            </S.ShortDescription>
          </Col>
          <Col md={4} align={'center'}>
            <Heading tag={'h2'}>
              <Logo title={brand.display_name} logo={brand.logo.localFile} svgWidth={'240px'} svgHeight={'80px'}/>
            </Heading>
            <Button as={Link} to={`${routes[brand.type.type][lang]}/${brand.slug}`} title={brand.display_name}>
              <FormattedMessage id='templates.brands.moreButton'/>
            </Button>
          </Col>
        </Row>
      </Container>
      <Separator/>
    </>
  )
};

const BrandPromoterGrid = props => {
  const { brand, count, lang } = props;
  const translations = findT(brand.translations, lang);
  return (
    <>
      <S.Brand count={count}>
        <Heading tag={'h2'}>
          <Logo title={brand.display_name} logo={brand.logo.localFile} svgWidth={'240px'} svgHeight={'80px'}/>
        </Heading>
        {brand.header_images[0] && <div>
          <S.StyledImg fluid={brand.header_images[0].file.localFile.childImageSharp.fluid} alt={brand.display_name} loading='eager'/>
        </div>}
        <h2>{brand.display_name}</h2>
        <S.ShortDescription>
          {translations.short_description
            ? <p>{translations.short_description}</p>
            : <HTMLEllipsis unsafeHTML={translations.description} maxLine={3} ellipsis={'...'}/>
          }
        </S.ShortDescription>
        <div className='int-link-container'>
          <Button as={Link} to={`${routes[brand.type.type][lang]}/${brand.slug}`} title={brand.display_name}>
            <FormattedMessage id='templates.brands.moreButton'/>
          </Button>
        </div>
      </S.Brand>
    </>
  )
};

const BrandsPageTemplate = props => {
  const { type, lang } = props.pageContext;
  const brands = props.data.allDirectusBrands.nodes;
  const { config } = useTheme();
  const { listStyle, showTypeDescription } = config.templates.brands;
  const brandTypeInfo = props.data.directusBrandTypes;
  const infoTranslations = findT(brandTypeInfo.translations, lang);
  return (
    <>
      <SEO title={infoTranslations.display_name}/>
      <RandomBrandHeaderImages type={brandTypeInfo.type}/>
      <BrandsSubnavigation type={type}/>
      <Wrapper>
        <Spacer/>
        <Heading>{infoTranslations.display_name}</Heading>
        {showTypeDescription && infoTranslations.description &&
        <S.BrandTypeDescription
          dangerouslySetInnerHTML={{ __html: infoTranslations.description }}/>
        }
        <Separator/>
        {listStyle === 'list'
          ? brands.map(({ brand }) => <BrandPromoterList key={brand.slug} brand={brand} lang={lang}/>)
          : <S.PromoterContainer>
            {brands.map(({ brand }) => <BrandPromoterGrid key={brand.slug} brand={brand} lang={lang}
                                                          count={brands.length}/>)}
          </S.PromoterContainer>
        }
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
};

export const query = graphql`
  query($type: String = "undefined")  {
    directusBrandTypes(type: {eq: $type}) {
      type
      translations {
        language
        display_name
        subtitle
        description
        short_description
      }
    }
    allDirectusBrands(filter: {brand: {type: {type: {eq: $type}}, status: {eq: "published"}}, id: {ne: "dummy"}}, sort: {fields: brand___brand_rating, order: DESC}) {
      nodes {
        sort
        ...BrandsCore
        ...BrandHeaderImages
        brand {
          header_images {
            file {
              width
              height
              localFile {
                name
                childImageSharp {
                  fluid(maxWidth: 1980, maxHeight: 700, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BrandsPageTemplate;