import styled from '@emotion/styled';
import Img    from 'gatsby-image';

export const ShortDescription = styled.div`
  font-size: 0.9rem;
  opacity: 0.6;
  text-align: justify;
  margin-top: 20px;
`;

export const BrandTypeDescription = styled.div`
  h2 {
    text-align: center;
  }
  p {
    text-align: justify;
    font-size: 0.9rem;
  }
`;

export const StyledImg = styled(Img)`
  border: 1px solid black;
`;

// Grid List Style
export const PromoterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h2 {
    margin-top: 30px;
  }
`;

export const Brand = styled.div`
  width: ${props => props.count > 1 ? '48%' : '100%'};
  @media all and (max-width: 900px) {
    width: 100%;
  }
  position: relative;
  margin-bottom: 80px;
  padding-bottom: 80px;
  img {
    width: 160px;
  }
  .int-link-container {
    position: absolute;
    bottom: 0;
  }
`;