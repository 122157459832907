import { graphql, useStaticQuery } from 'gatsby';

const useTheme = () => {
  const data =  useStaticQuery(query);
  return data.theme;
};

export default useTheme;

const query = graphql`
  query {
    theme {
      config {
        templates {
          brands {
            listStyle
            showTypeDescription
          }
        }
      }
    }
  }
`;
